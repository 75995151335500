import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height justify-center",attrs:{"id":"login","tag":"section"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VSlideYTransition,{attrs:{"appear":""}},[_c(VCard,{staticClass:"px-5 py-3",attrs:{"color":"white","light":"","max-width":"100%","width":"400"}},[_c(VCardText,{staticClass:"text-center"},[_c('div',{staticClass:"text-center"},[_c('img',{attrs:{"alt":"DXA","src":require("../../../../assets/dxa-with-x-green.png"),"height":"70px"}})]),_c(VForm,{ref:"form",attrs:{"lazy-validation":_vm.lazy},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.checkForm()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{ref:"password",attrs:{"color":"primary","label":_vm.$t('new_password'),"prepend-icon":"mdi-lock-outline","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                _vm.required,
                _vm.min_valid,
                _vm.uppercase_required,
                _vm.lowercase_required,
                _vm.number_required,
                _vm.special_required,
                _vm.accent_valid ],"type":_vm.show1 ? 'text' : 'password',"name":"password","counter":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VTextField,{staticClass:"mb-8",attrs:{"color":"primary","label":_vm.$t('re_password'),"prepend-icon":"mdi-lock-outline","append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.required, _vm.passwordsMatch],"type":_vm.show2 ? 'text' : 'password',"name":"input-10-2"},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.rePassword),callback:function ($$v) {_vm.rePassword=$$v},expression:"rePassword"}}),_c('pages-btn',{staticClass:"v-btn--text",attrs:{"block":"","type":"submit","color":"primary"}},[_vm._v(_vm._s(_vm.$t("save")))])],1),(_vm.error)?_c(VAlert,{staticClass:"ma-2",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.error))+" ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }