<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <v-card
          color="white"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <v-card-text class="text-center">
            <div class="text-center">
              <img
                alt="DXA"
                src="../../../../assets/dxa-with-x-green.png"
                height="70px"
              />
            </div>
            <v-form
              @submit.stop.prevent="checkForm()"
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-text-field
                @keydown.space.prevent
                color="primary"
                :label="$t('new_password')"
                prepend-icon="mdi-lock-outline"
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[
                  required,
                  min_valid,
                  uppercase_required,
                  lowercase_required,
                  number_required,
                  special_required,
                  accent_valid,
                ]"
                :type="show1 ? 'text' : 'password'"
                name="password"
                ref="password"
                counter
                @click:append="show1 = !show1"
              />

              <v-text-field
                class="mb-8"
                color="primary"
                v-model="rePassword"
                :label="$t('re_password')"
                prepend-icon="mdi-lock-outline"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[required, passwordsMatch]"
                :type="show2 ? 'text' : 'password'"
                name="input-10-2"
                @click:append="show2 = !show2"
              />

              <pages-btn
                block
                type="submit"
                color="primary"
                class="v-btn--text"
                >{{ $t("save") }}</pages-btn
              >
            </v-form>
            <v-alert class="ma-2" v-if="error" type="error">
              {{ $t(error) }}
            </v-alert>
          </v-card-text>
        </v-card>
        <!-- </base-material-card> -->
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import PagesBtn from "@/views/pages/components/Btn.vue";
export default {
  name: "NewPassword",

  components: {
    "pages-btn": PagesBtn,
  },
  methods: {
    checkForm: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      var result = this.$refs.form.validate();
      if (result) {
        this.save_new_password();
      } else {
        this.loading = false;
      }
    },
    save_new_password: function () {
      var data = {
        NewPassword: this.password,
        Code: parseInt(this.$route.query.pin),
        UserId: this.$route.query.user_id,
      };
      var client = new ApiService();
      client
        .postRequest("user/forgot-password/reset", data)
        .then((result) => {
          this.loading = false;
          this.$toast.success(this.$t(result.message));
          this.$router.push({ path: "user/auth" });
        })
        .catch((err) => {
          this.$toast.error(err.body.message);
          this.loading = false;
        });
    },
  },
  computed: {
    passwordsMatch() {
      return () =>
        this.password === this.rePassword || this.$t("pswds_dnt_match");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    min_valid() {
      return (v) => v.length >= 8 || this.$t("min_char");
    },
    special_valid() {
      return (v) =>
        !/[*|\":<>[\]{}`\\()';&$]/.test(v) || this.$t("special_char");
    },
    accent_valid() {
      return (v) => !/[À-ÖØ-öø-ÿ]/.test(v) || this.$t("accent_char");
    },
    special_required() {
      return (v) => /[*&$!@#?]/.test(v) || this.$t("special_required");
    },
    uppercase_required() {
      return (v) => /[A-Z]/.test(v) || this.$t("uppercase_required");
    },
    number_required() {
      return (v) => /[0-9]/.test(v) || this.$t("number_required");
    },
    lowercase_required() {
      return (v) => /[a-z]/.test(v) || this.$t("lowercase_required");
    },
  },
  data: () => ({
    loading: false,
    valid: true,
    lazy: false,
    error: null,
    show1: false,
    show2: false,
    password: "",
    rePassword: "",
  }),
};
</script>

<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
